var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-container',_vm._g(_vm._b({attrs:{"title":_vm.id === 'new' ? 'Add Communication Type' : 'Edit Communication Type',"id":_vm.id,"provider":_vm.provider,"crud":_vm.crud,"item":_vm.item,"routeAfterSaveAndClose":function (item) { return ({ name: 'CommunicationTypeList' }); },"routeAfterSave":function (item) { return ({ name: 'CommunicationTypeForm', params: { id: item.id } }); }},on:{"update:item":function($event){_vm.item=$event}}},'form-container',Object.assign({}, _vm.$attrs, _vm.$props),false),_vm.$listeners),[(_vm.item)?_c('div',{},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('q-input',{attrs:{"filled":"","label":"Name","rules":[_vm.$rules.required]},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('div',{staticClass:"col-12"},[_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('q-card-section',{attrs:{"title":""}},[_vm._v("Metrics")]),_c('q-card-section',[_c('sub-array-form',{attrs:{"array":_vm.item.metrics,"generator":function (id) { return ({
                  id: id,
                  communicationTypeId: _vm.item.id,
                  title: '',
                  unitOfMeasurement: 'Text',
                }); },"validateObject":function (i) { return i.title !== ''; }},on:{"update:array":function($event){return _vm.$set(_vm.item, "metrics", $event)}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"filled":"","label":"Title"},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"filled":"","options":['Text', 'Number'],"label":"Unit Of Measurement"},model:{value:(item.unitOfMeasurement),callback:function ($$v) {_vm.$set(item, "unitOfMeasurement", $$v)},expression:"item.unitOfMeasurement"}})],1)])]}}],null,false,359825531)})],1)],1)],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }